.join_container {
    width: 75%;
    padding:40px;
    margin:0 auto;
    margin-top: 50px;
    border-radius: 50px;
    line-height: 2;
    color: #fff;
    display: block;
    background-color: #505780;
    p {
        font-size: 20px;
        letter-spacing: 2px;
        margin: 30px;
    }
    h1 {
        line-height: 1;
        margin-left: 1rem;
    }
    .join_us {
        color: #fff;
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: none;
        position: relative;
        margin: 0 auto 10px auto; // Center alignment
        border-radius: 40px;
        background: #57BA70;
        max-width: 150px;
        display: block; // Ensure it's a block element
        text-align: center; // Center text inside the button
        padding: {
            top: 0.5rem;
            bottom: 0.5rem;
            left: 3rem;
            right: 3rem;
        }
        &:hover {
            background-color: #3e8550;
        }
    }

    @media screen and (max-width: 768px) {
        padding: {
            top:40px;
            bottom:40px;
            left:10px;
            right:10px;
        }
        width:90%;
        border-radius: 25px;
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
            letter-spacing: 1px;
            margin: 20px;
        }
        
    }
}