
@font-face {
    font-family: "Taipei-Sans";
    src: url("https://cdn.jsdelivr.net/npm/taipei-sans-tc");
}
@font-face {
    font-family: "Taipei-Sans";
    src: url("https://cdn.jsdelivr.net/npm/taipei-sans-tc/dist/Bold/TaipeiSansTCBeta-Bold.css");
    font-weight: bold;
}

* {
    margin:0;
    padding:0;
    font-family: 'Taipei-Sans',Noto Sans TC, sans-serif;
    
}
.App {
    background-color: #30344D;
}
.container {
    align-items: center;

    padding: {
        left:40px;
        right:40px;
        top:50px;
        bottom:50px;
    }

    @media screen and (max-width:768px) {
        padding: {
            left:0px;
            right:0px;
            top:20px;
            bottom:20px;
        }
        
    }

}